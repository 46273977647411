<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { baiduApiResult, downloadAndroidApp, downloadIosApp } from '../..//utils';

const lanList = ref([
  require("../../assets/images/web/China.png"),
  require("../../assets/images/web/us.png"),
  require("../../assets/images/web/uk.png"),
  require("../../assets/images/web/Germany.png"),
  require("../../assets/images/web/France.png"),
  require("../../assets/images/web/Japan.png"),
  require("../../assets/images/web/sk.png"),
  require("../../assets/images/web/Spain.png"),
  require("../../assets/images/web/Russia.png"),
  require("../../assets/images/web/Italy.png"),
  require("../../assets/images/web/country_10.png"),
  require("../../assets/images/web/Thailand.png"),
  require("../../assets/images/web/Vietnam.png"),
  require("../../assets/images/web/country_14.png"),
  require("../../assets/images/web/coutry_15.png"),
])


const route = useRoute();

const onDownLoad = (type) => {
  if (type == 0) {
    //安卓下载
    if (route.query.bd_vid) {
      baiduApiResult(route.query.bd_vid, 0)
    }
    downloadAndroidApp()
  } else {
    // ios下载
    if (route.query.bd_vid) {
      baiduApiResult(route.query.bd_vid, 1)
    }
    downloadIosApp()
  }
}

</script>
<template>
  <div class="header">
    <img src="../../assets/images/web/header_triangle.png" alt="" class="triangle-img">
    <div class="head-logo">
      <img src="../../assets/images/web/header_logo.png" class="logo-img">
      <div class="head-text">
        <img src="../../assets/images/web/header_text_logo.png" class="head-text-logo">
        <div class="text">100W+学习者共同选择，与先进AI学习60种外语</div>
      </div>
    </div>
    <div class="header-content">
      <img src="../../assets/images/web/header_slider1.png" alt="" class="slide-img">
      <div class="content-text">
        <img src="../../assets/images/web/header_cylinder.png" class="cy_linder">
        <div class="title">
          Crossing Language <br>Boundaries with <br>TalkAI
        </div>
        <div class="desc">
          <div class="t-desc">用&nbsp;<span class="text">TalkAI<span class="block"></span></span>&nbsp;跨越语言边界</div>
          <div class="b-desc">100W+外语学习者的共同选择</div>
        </div>
        <div class="down-btn">
          <div class="btn" @click="onDownLoad(1)">
            IOS下载
            <img src="../../assets/images/web/download_icon.png" alt="" width="24px">
          </div>
          <div class="btn" @click="onDownLoad(0)">
            Android下载
            <img src="../../assets/images/web/download_icon.png" alt="" width="24px">
          </div>
        </div>
      </div>
      <div class="content-img-box">
        <img src="../../assets/images/web/dao_triangle.png" alt="" class="dao_triangle">
        <img src="../../assets/images/web/header_ai_video.png" alt="" class="ai-video-img">
      </div>
    </div>
    <div class="support-lan">
      <div class="lan-text">支持超过60种语言</div>
      <div class="img-box">
        <img :src="src" class="lan-img" v-for="src in lanList">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  padding: 44px 0 52px 35px;
  background-image: url("../../assets/images/web/header_bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;

  .triangle-img {
    position: absolute;
    top: 64px;
    right: 290px;
    width: 160px;
    height: 106px;
    object-fit: cover;
    z-index: 9;
  }

  .head-logo {
    display: flex;
    align-items: center;
    .logo-img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
    .head-text {
      margin-left: 10px;
      .head-text-logo {
        height: 30px;
        object-fit: cover;
      }
      .text {
        font-size: 12px;
        color: #28CD84;
        line-height: 16px;
        position: relative;
        top: -4px;
      }
    }
  }

  .header-content {
    display: flex;
    margin-top: 114px;

    .content-text {
      margin-left: 76px;
      position: relative;

      .cy_linder {
        position: absolute;
        left: 425px;
        top: -16px;
      }

      .title {
        font-family: "Futura";
        font-size: 60px;
        font-weight: 700;
        line-height: 90px;
      }

      .desc {
        font-size: 50px;

        .t-desc {
          line-height: 66px;
          font-weight: 500;

          .text {
            font-family: "Futura" !important;
            font-weight: 700;
            position: relative;

            .block {
              display: inline-block;
              position: absolute;
              left: -2px;
              right: 0;
              bottom: 0px;
              width: 170px;
              height: 35px;
              background-color: #D3FF99;
              z-index: -1;
            }
          }
        }

        .b-desc {
          margin-top: 6px;
          line-height: 70px;
          font-weight: 600;
        }
      }

      .down-btn {
        display: flex;
        margin-top: 53px;

        .btn {
          padding: 0 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 180px;
          height: 48px;
          border-radius: 34px;
          background-color: #FB5656;
          margin-right: 24px;
          color: #fff;
        }
      }
    }

    .content-img-box {
      position: relative;

      .ai-video-img {
        object-fit: cover;
      }

      .dao_triangle {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 130px;
        height: 124px;
        object-fit: cover;
      }
    }

  }

  .support-lan {
    margin: 0 auto;
    margin-top: 97px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 48px;
    width: 1168px;
    background-image: url('../../assets/images/web/support_lan_bg.png');
    background-repeat: no-repeat;

    .lan-text {
      font-size: 12px;
      font-weight: 600;
      margin-right: 18px;
    }

    .img-box {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .lan-img {
        width: 36px;
        height: 24px;
        object-fit: cover;
      }
    }
  }
}
</style>