<script setup>
import RoleItem from './RoleItem.vue';
import { ref } from 'vue'
const roleList = ref([
  {
    avatar: require("/src/assets/images/web/Joey.png"),
    role: "留学生",
    bgColor: "#65F9B8",
    roleBgColor: "#00E17E",
    name: "Joey",
    title: "·轻松实现雅思8.0",
    topDesc: '我想要拿高分，当我第一次使用TalkAI的时候，我就知道找对了！',
    bottomDesc: 'TalkAI的真实对话和模拟考试让我获益良多，不仅每月更新雅思新题，在对话后TalkAI还会为我提供评估报告，帮助我针对性练习，让我信心满满迎接考试挑战！'
  },
  {
    avatar: require("/src/assets/images/web/cherry.png"),
    role: "外国旅居者",
    bgColor: "#C8FC8B",
    roleBgColor: "#6CF105",
    name: "Cherry Yang",
    title: "·迅速融入外国生活",
    topDesc: '在外国我一点都不胆怯，不论第一次点餐、乘坐公交、租房购物，我都非常轻松的和外国人沟通！原因很简单，我已经和TalkAI提前练习了！',
    bottomDesc: 'TalkAI提供覆盖了日常生活、旅行、校园等多种多样的场景，让我迅速的掌握交流技巧，更快的融入外国生活！TalkAI也是我的专属实时翻译机，去任何地方做任何事情，都变得底气十足！'
  },
  {
    avatar: require("/src/assets/images/web/jessica.png"),
    role: "外企人",
    bgColor: "#FFFB99",
    roleBgColor: "#FDD711",
    name: "Jessica Li",
    title: "·提升职场英语神器",
    topDesc: '外企外贸行业，职场流利的口语必不可少，作为新人小白也会面临挑战，在严肃的职场环境中如何自然、流利的沟通？',
    bottomDesc: 'TalkAI很好帮我解决了这个问题，真实的场景对话与演练，1v1的口语训练实时评分，还支持60+的语言学习，帮助我在职场乘风破浪！'
  },
  {
    avatar: require("/src/assets/images/web/lisa.png"),
    role: "英语教师",
    bgColor: "#FFE68D",
    roleBgColor: "#FDD711",
    name: "Lise",
    title: "·完善教学的最后一块拼图",
    topDesc: '作为一名英语教师和德语教师，我非常愿意推荐我的学生使用TalkAI，在口语的学习中，语言环境是至关重要的。',
    bottomDesc: 'TalkAI通过视频通话和语音对答的方式，激发，鼓励，刺激学生开口说，用心讲！作为提升学生口语的工具，TalkAI也能实时追踪学习进度，帮助我完善教学指标和教学任务，是不可多得的一款AI学习工具！'
  },
])
</script>

<template>
  <div class="home-role-box">
    <img src="../../assets/images/web/role_line.png" alt="" class="role_line">
    <img src="../../assets/images/web/slider_4.png" alt="">
    <div class="role-content">
      <img src="../../assets/images/web/cylinder_1.png" alt="" class="cylinder-img">
      <div class="item" v-for="(role,index) in roleList" :style="{marginRight:(index+1)%2 == 0? '76px':0}">
        <RoleItem :roleInfo="role"></RoleItem>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home-role-box {
  padding-left: 35px;
  margin-top: 124px;
  display: flex;
  align-items: center;
  position: relative;
  .role_line {
    position: absolute;
    right: 42px;
    bottom: 40px;
    z-index: 9;
  }
  .role-content  {
    margin-left: 76px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    .item {
      width: 45%;
      height: 364px;
      margin-bottom:78px;
    }
    .cylinder-img {
      position: absolute;
      width: 170px;
      height: 170px;
      top: 364px;
      left: -40px;
      z-index: 9;
    }
  }
}
</style>