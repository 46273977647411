
import axios from "axios";

function inWeixin() {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}
function isIos() {
  return navigator.userAgent.toLowerCase().indexOf('ipad') > -1 ||
    navigator.userAgent.indexOf('Mac OS') > -1 || navigator.userAgent.toLowerCase().indexOf('iPhone') > -1
}

export const downloadAndroidApp = () => {
  if (inWeixin()) {
    alert('请点击右上角菜单使用浏览器打开');
    return
  }
  axios.get('https://chat-speak.amazenote.com/api/index/apkDownloadUrl').then((result) => {
    console.log(result);
    if (result.data.code === 0) {
      const downUrl = result.data.data.url
      window.open(downUrl)
    }
  }).catch((err) => {
    alert('请求错误')
  });
}

export const downloadIosApp = () => {
  if (inWeixin()) {
    alert('请点击右上角菜单使用浏览器打开');
    return
  }
  window.open('https://apps.apple.com/cn/app/id6447748874');
}
export const isMobile = () => {
  let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return flag;
}

export const baiduApiResult = (bd_vid,type) => {
  try {
    axios.get('https://ttalkai.com/api/talkAiSide/baidu/wwwOCPC',{
      params :{
        'bd_vid':bd_vid || '',
        'btn_type':type
      }
    }).then((res) => {
     
    })
  } catch (error) {
    // console.log(`百度归因失败 ${error}`);
  }
}