<script setup>
import { onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ContentItem from '../components/home/ContentItem.vue';
import HomeContentItem from '../components/home/HomeContentItem.vue';
import HomeFooter from "../components/home/HomeFooter.vue";
import HomeHeader from '../components/home/HomeHeader.vue';
import HomeRole from "../components/home/HomeRole.vue";
import UserServe from "../components/home/UserServe.vue";
import { baiduApiResult, isMobile } from '../utils/index';

const route = useRoute();
const router = useRouter();



onBeforeMount(() => {

  if (isMobile()) {
    if (route.query.bd_vid) {
      router.push({
        name: 'indexH5',
        query: {
          bd_vid: route.query.bd_vid
        }
      })
    } else {
      router.push('/indexH5')
    }
  } else {
    if (route.query.bd_vid) {
      baiduApiResult(route.query.bd_vid, 2)
    }
  }
})
</script>

<template>
  <div class="home-container">
    <HomeHeader />
    <HomeContentItem :imgLeft="true" :slideImg="require('../assets/images/web/slider_2.png')">
      <template v-slot:centerContent>
        <img src="../assets/images/web/content_bg_1.png">
      </template>
      <template v-slot:rightContent>
        <ContentItem :imgSrc="require('../assets/images/web/content_item_img_1.png')" title="一对一AI外教陪聊，探索智能AI交流的无限可能!"
          topDesc="作为全球目前先进的口语AI工具，无论您是初学者还是想提升口语水平，我都能提供24小时的在线陪聊服务。"
          bottomDesc="我支持多达60多种语言，不管您是想学习英语、德语、日语，还是西班牙语……我都能胜任！">
        </ContentItem>
      </template>
    </HomeContentItem>
    <!-- <HomeContentItem 
    :imgLeft="false" bgColor="#2DCE87" :slideImg="require('../assets/images/web/slider_3.png')">
    <template v-slot:centerContent>
      <ContentItem 
      :imgSrc="require('../assets/images/web/content_item_img_2.png')"
      title="一对一AI外教陪聊，探索智能AI交流的无限可能!"
      topDesc="作为全球目前先进的口语AI工具，无论您是初学者还是想提升口语水平，我都能提供24小时的在线陪聊服务。"
      bottomDesc="我支持多达60多种语言，不管您是想学习英语、德语、日语，还是西班牙语……我都能胜任！">
      </ContentItem>
    </template>
    <template v-slot:rightContent>
      <img src="../assets/images/web/content_bg_2.png">
    </template>
  </HomeContentItem> -->
    <HomeRole />
    <UserServe />
    <HomeFooter />
  </div>
</template>

<style lang="scss" scoped>
.home-container {
  max-width: 1440px;
  margin: 0 auto;
}
</style>
