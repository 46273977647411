<script setup>
import { ref } from 'vue';



import { useRoute } from 'vue-router';
import { baiduApiResult } from '../../utils';

const route = useRoute()

const onClick = (type) => {
  if(route.query.bd_vid){
    baiduApiResult(route.query.bd_vid,type)
  }
}

const userList = ref([
  {
    url: require("../../assets/images/web/shzx.png"),
    name: "上海市第三中学",
    bgColor: '#65F9B8'
  },
  {
    url: require("../../assets/images/web/bd.png"),
    name: "百度",
    bgColor: '#FFBD6F'

  },
  {
    url: require("../../assets/images/web/fsk.png"),
    name: "富士康",
    bgColor: '#FFE55F'

  },
  {
    url: require("../../assets/images/web/xuehua.png"),
    name: "雪花啤酒",
    bgColor: '#A6FC63'
  },
])
</script>

<template>
  <div class="user-serve-box">
    <img src="../../assets/images/web/slider_5.png" alt="">
    <div class="user">
      <div class="title">
        · TalkAI服务用户 ·
        <img src="../../assets/images/web/user_line.png" alt="">
      </div>
      <div class="user-item-box">
        <div class="item" v-for="item in userList" :style="{ backgroundColor: item.bgColor }">
          <img :src="item.url" class="user-img">
          {{ item.name }}
        </div>
      </div>
      <div class="serve-text">
        · 服务合作联系方式 ·
      </div>
      <div class="contact">
        <a href="mailto:amazingcompany@163.com" style="color: black;" @click="onClick(4)">官方联系合作邮箱：amazingcompany@163.com</a>
        <a href="tel:17388758327" style="color: black;" @click="onClick(3)">&nbsp;&nbsp;&nbsp;联系电话：17388758327</a>
      </div>
      <div class="mg-t-20 company-desc">· 公司简介 ·</div>
      <div class="com-desc">
        深圳市惊叹科技有限公司成立于2021年12月03日，注册地位于深圳市宝安区新安街道海滨社区N23区海天路15-1号卓越宝中时代广场一期A栋1109，法定代表人为周晓秀。
        经营范围包括技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；人工智能应用软件开发；网络与信息安全软件开发；软件开发；信息技术咨询服务；
        人工智能公共服务平台技术咨询服务；信息咨询服务（不含许可类信息咨询服务）；专业设计服务；社会经济咨询服务；
        教育咨询服务（不含涉许可审批的教育培训活动）；文艺创作；企业形象策划；广告设计、代理；广告制作；企业管理咨询；组织文化艺术交流活动；市场营销策划；
        咨询策划服务；电影摄制服务；翻译服务；国内贸易代理；贸易代理；电子产品销售。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）。
      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-serve-box {
  background-color: #f6f6f6;
  padding-left: 35px;
  padding-top: 212px;
  display: flex;
  align-items: center;

  .user {
    margin-left: 76px;
    margin: 0 auto;

    .title {
      font-size: 32px;
      font-weight: 600;
      line-height: 50px;
      text-align: center;
      position: relative;

      img {
        position: absolute;
        left: 52%;
        top: -40px;
        width: 380px;
        height: 120px;
        transform: translateX(-50%);
      }
    }

    .user-item-box {
      display: flex;
      justify-content: space-around;
      margin-top: 144px;

      .item {
        position: relative;
        height: 100px;
        line-height: 100px;
        width: max-content;
        padding: 0 70px;
        border-radius: 24px;
        transform: skewX(-20deg);
        font-family: "YouSheBiaoTiHei";
        font-size: 32px;
        font-weight: 400;
        text-align: center;
        margin-right: 40px;


        .user-img {
          position: absolute;
          top: -40px;
          right: 24px;
          width: 64px;
          height: 64px;
          transform: skewX(20deg);

        }
      }
    }

    .serve-text {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 50px;
      margin-top: 145px;

    }

    .contact {
      margin: auto;
      height: 32px;
      padding: 0 16px;
      line-height: 32px;
      width: max-content;
      font-size: 12px;
      font-weight: 500;
      color: #0E1F00;
      background-color: #D3FF99;
      border: 1.5px solid #fff;
      border-radius: 85px;
    }
    .company-desc {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 50px;
    }
    .com-desc {
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      text-indent: 2em;
      padding: 0 100px;
    }
  }
}
</style>